import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Typography, Upload, Select, message, Card, Checkbox } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
	addAchievement,
	deleteAchievement,
	getAchievement,
	getAchievementCategories,
	updateAchievement,
} from "../services/AchievementService";
import AchievementList from "../components/Achievement/AchievementList";
import { getCategories } from "../services/CategoryService";
import { ClipLoader } from "react-spinners";

const { Title } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

// const moodOptions = ["Sehr Schlecht", "Schlecht", "Ok", "Gut", "Sehr gut"];
const moodOptions = [
	{ name: "Sehr Schlecht", type: "BAD" },
	{ name: "Schlecht", type: "OK" },
	{ name: "Ok", type: "NORMAL" },
	{ name: "Gut", type: "GOOD" },
	{ name: "Sehr gut", type: "SUPER" },
  ];

const AchievementEditor = () => {
	const [file, setFile] = useState(null);
	const [fileList, setFileList] = useState([]);
	const [previewUrl, setPreviewUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [achievement, setAchievement] = useState({
		title: "",
		description: "",
		xpToEarn: "",
		isRepeatable: false,
		xpRequired: "",
		pointsRequired: "",
		videosSeen: "",
		tasksDone: "",
		quizzesDone: "",
		affirmationsDone: "",
		moodTracked: "",
		moodTypeTracked: "",
		loginStreak: "",
		categories: [],
	});
	const [selectedMoods, setSelectedMoods] = useState([]);
	const [allAchievements, setAllAchievements] = useState([]);
	const [isEditMode, setIsEditMode] = useState(false);
	const [categories, setCategories] = useState([]);

	// Handle file upload and set preview image
	const handleFileUpload = (file) => {
		const preview = URL.createObjectURL(file);
		setPreviewUrl(preview);

		const newFile = {
			uid: file.uid,
			name: file.name,
			status: "done",
			url: preview,
		};

		setFileList([newFile]);
		setFile(file);
		return false; // Prevent automatic upload
	};

	const handleInput = (e) => {
		const { name, value } = e.target;
		setAchievement((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleMoodSelect = (values) => {
		
		const selectedNames = moodOptions
			.filter(mood => values.includes(mood.name))
			.map(mood => mood.name);

		const selectedTypes = moodOptions
			.filter(mood => values.includes(mood.name))
			.map(mood => mood.type);

		setSelectedMoods(selectedNames);
			
		setAchievement((prevState) => {
			const updatedMoodTypeTracked = { ...prevState.moodTypeTracked };
			selectedTypes.forEach((mood) => {
				if (!updatedMoodTypeTracked[mood]) {
					updatedMoodTypeTracked[mood] = "";
				}
			});
			return { ...prevState, moodTypeTracked: updatedMoodTypeTracked };
		});
		
		
		
	};

	const handleMoodNumberChange = (moodValue, value) => {
		const selectedMood = moodOptions.find(mood => mood.name === moodValue);
			
		setAchievement((prevState) => ({
			...prevState,
			moodTypeTracked: {
				...prevState.moodTypeTracked,
				[selectedMood.type]: value,
			},
		}));
	};

	const handleSave = async () => {
		const formData = new FormData();
		formData.append("title", achievement.title);
		formData.append("description", achievement.description);

		if (achievement.xpToEarn) {
			formData.append("xpToEarn", achievement.xpToEarn);
		}

		if(achievement.isRepeatable)
			formData.append("isRepeatable", achievement.isRepeatable);
		else
			formData.append("isRepeatable", false);

		if (achievement.xpRequired) {
			formData.append("xpRequired", achievement.xpRequired);
		}
		if (achievement.pointsRequired) {
			formData.append("pointsRequired", achievement.pointsRequired);
		}
		if (achievement.videosSeen) {
			formData.append("videosSeen", achievement.videosSeen);
		}
		if (achievement.tasksDone) {
			formData.append("tasksDone", achievement.tasksDone);
		}
		if (achievement.quizzesDone) {
			formData.append("quizzesDone", achievement.quizzesDone);
		}
		if (achievement.affirmationsDone) {
			formData.append("affirmationsDone", achievement.affirmationsDone);
		}
		if (achievement.moodTracked) {
			formData.append("moodTracked", achievement.moodTracked);
		}
		if (achievement.moodTypeTracked) {
			formData.append("moodTypeTracked", JSON.stringify(achievement.moodTypeTracked));
		}
		if (achievement.loginStreak) {
			formData.append("loginStreak", achievement.loginStreak);
		}

		const categoryRequirements = achievement.categories.map((categoryId) => ({
			categoryId,
			minLevel: achievement.categoryValues[categoryId],
		}));

		formData.append("categoryRequirements", JSON.stringify(categoryRequirements));

		formData.append("file", file);

		try {
			setLoading(true);
			if (isEditMode) {
				await updateAchievement(achievement.id, formData);
				setAchievement({
					title: "",
					description: "",
					xpToEarn: "",
					isRepeatable: false,
					xpRequired: "",
					pointsRequired: "",
					videosSeen: "",
					tasksDone: "",
					quizzesDone: "",
					affirmationsDone: "",
					moodTracked: "",
					moodTypeTracked: "",
					loginStreak: "",
					categories: [],
				});

				setSelectedMoods([]);

				// clear previewimages
				setPreviewUrl(null);
				setFileList([]);

				message.success("Trophäe erfolgreich gespeicher!");
			} else {
				await addAchievement(formData);
				setAchievement({
					title: "",
					description: "",
					xpToEarn: "",
					isRepeatable: false,
					xpRequired: "",
					pointsRequired: "",
					videosSeen: "",
					tasksDone: "",
					quizzesDone: "",
					affirmationsDone: "",
					moodTracked: "",
					moodTypeTracked: "",
					loginStreak: "",
					categories: [],
				});

				setSelectedMoods([]);

				// clear previewimages
				setPreviewUrl(null);
				setFileList([]);
				message.success("Trophäe erfolgreich gespeichert!");
			}
			setLoading(false);
			fetchAchievemnt();
		} catch (error) {
			message.error("Es ist ein Fehler aufgetreten.");
			setLoading(false);
		}
	};

	const handleCategorySelect = (values) => {
		setAchievement((prevState) => ({
			...prevState,
			categories: values,
		}));
	};

	const handleCategoryValueChange = (category, value) => {
		setAchievement((prevState) => ({
			...prevState,
			categoryValues: {
				...prevState.categoryValues,
				[category]: value,
			},
		}));
	};

	const handleEdit = async (achievement) => {
		try {
			const res = await getAchievementCategories(achievement.id);

			const categoryIds = res.map((cat) => cat.category);
			const categoryValues = res.reduce((acc, cat) => {
				acc[cat.category] = cat.value;
				return acc;
			}, {});

			const moodTypeTracked = achievement.moodTypeTracked || {};
			const selectedMoods = Object.keys(moodTypeTracked);

			setAchievement({
				...achievement,
				id: achievement.id,
				categories: categoryIds,
				categoryValues,
				moodTypeTracked, // Set the moodTypeTracked data
				isRepeatable: achievement.isRepeatable ?? false,
			});

			setSelectedMoods(selectedMoods);

			setIsEditMode(true);
		} catch (error) {
			message.error("Es ist beim Fehler beim Laden aufgetreten.");
		}
	};

	const handleDelete = async (id) => {
		await deleteAchievement(id);
		message.success("Trophäe gelöscht");
		fetchAchievemnt();
	};

	const uploadProps = {
		name: "file",
		multiple: false,
		beforeUpload: handleFileUpload,
		onRemove: () => {
			setFile(null);
		},
		fileList: fileList,
		accept: ".jpg,.jpeg,.png,.gif,.svg",
	};

	const fetchAchievemnt = async () => {
		const res = await getAchievement();
		setAllAchievements(res);
	};

	const fetchCategories = async () => {
		const response = await getCategories();
		setCategories(response);
	};

	useEffect(() => {
		fetchAchievemnt();
		fetchCategories();
	}, []);

	return (
		<div className='layout-content'>
			<Row justify='center'>
				<Col xs={24}>
					<Row gutter={12}>
						<Col xs={12}>
							<Col xs={24}>
								<Title level={5} style={{ marginTop: "10px" }}>
									<span style={{ color: "red" }}>*</span> Titel
								</Title>
								<Input
									name='title'
									placeholder='Titel der Trophäe angeben'
									value={achievement.title}
									onChange={handleInput}
									style={{ marginBottom: "10px" }}
								/>
							</Col>
							<Col xs={24}>
								<Title level={5} style={{ marginTop: "10px" }}>
									Beschreibung
								</Title>
								<Input.TextArea
									name='description'
									placeholder='Beschreibung angeben'
									value={achievement.description}
									onChange={handleInput}
									style={{ marginBottom: "10px" }}
								/>
							</Col>
							<Row gutter={12} justify='center' align='middle'>
								{/* Amount of XP user gets when he gets the trophy (optional) */}
								<Col xs={16}>
									<Title level={5} style={{ marginTop: "10px" }}>
										Zu verdienende XP
									</Title>
									<Input
										name='xpToEarn'
										type='number'
										placeholder='Anzahl XP (optional)'
										value={achievement.xpToEarn}
										onChange={handleInput}
										style={{ marginBottom: "10px" }}
									/>
								</Col>
								{/* Checkbox if achievement is earnable multiple times */}
								<Col xs={8} style={{ paddingTop: 32 }}>
									<Checkbox
										name='isRepeatable'
										checked={achievement.isRepeatable}
										onChange={(e) =>
											setAchievement((prevState) => ({
												...prevState,
												isRepeatable: e.target.checked,
											}))
										}
									/>
									<span style={{ marginLeft: "10px" }}>Mehrfach erreichbar</span>
								</Col>
							</Row>
						</Col>
						<Col xs={12}>
							<Title level={5} style={{ marginTop: "10px" }}>
								<span style={{ color: "red" }}>*</span> Datei hochladen
							</Title>
							<Dragger
								{...uploadProps}
								style={{
									padding: "10px",
									width: "100%",
									borderRadius: "8px",
									backgroundColor: "#fafafa",
									border: "1px dashed #d9d9d9",
								}}
							>
								<p className='ant-upload-drag-icon'>
									<InboxOutlined style={{ color: "#1890ff", fontSize: "32px" }} />
								</p>
								<p className='ant-upload-text'>
									Klicke oder ziehe eine Datei in dieses Feld, um sie hochzuladen
								</p>
								{(previewUrl || achievement.imageURL) && (
									<div style={{ marginTop: 16 }}>
										<img
											src={previewUrl ?? achievement.imageURL}
											alt='preview'
											style={{ maxWidth: "100%", maxHeight: 100 }}
										/>
									</div>
								)}
							</Dragger>
						</Col>
					</Row>
					<Col
						xs={24}
						style={{
							height: "50vh",
							overflow: "scroll",
							backgroundColor: "AppWorkspace",
							padding: 26,
							borderRadius: 6,
						}}
					>
						<Title level={5}>Erforderliche XP</Title>
						<Input
							name='xpRequired'
							type='number'
							placeholder='erforderliche XP angeben (optional)'
							value={achievement.xpRequired}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>

						<Title level={5}>Erforderliche Kategorie Punkte</Title>

						<Select
							mode='multiple'
							style={{ width: "100%", marginBottom: "10px" }}
							placeholder='Kategorien auswählen'
							value={achievement.categories}
							onChange={handleCategorySelect}
						>
							{categories.map((category) => (
								<Option key={category.id} value={category.id}>
									{category.title}
								</Option>
							))}
						</Select>

						{achievement.categories?.map((cate) => {
							const category = categories.find((cat) => cat.id === cate) || {};
							return (
								<div key={cate} style={{ marginBottom: "10px" }}>
									<span>{category?.title || "Unbekannte Kategorie"}</span>
									<Input
										type='number'
										placeholder={`Erforderliche Punkte`}
										value={achievement?.categoryValues?.[cate] || ""}
										onChange={(e) =>
											handleCategoryValueChange(cate, e.target.value)
										}
										style={{
											width: "200px",
											marginTop: "5px",
											marginLeft: "10px",
										}}
									/>
								</div>
							);
						})}

						<Title level={5}>Anzahl angesehener Videos</Title>
						<Input
							name='videosSeen'
							type='number'
							placeholder='Anzahl angeben (optional)'
							value={achievement.videosSeen}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>

						<Title level={5}>Anzahl erledigter Aufgaben angeben</Title>
						<Input
							name='tasksDone'
							type='number'
							placeholder='Anzahl angeben (optional)'
							value={achievement.tasksDone}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>

						<Title level={5}>Anzahl abgeschlossener Quizze angeben</Title>
						<Input
							name='quizzesDone'
							type='number'
							placeholder='Anzahl angeben (optional)'
							value={achievement.quizzesDone}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>

						<Title level={5}>Anzahl abgehakter Affirmationen angeben</Title>
						<Input
							name='affirmationsDone'
							type='number'
							placeholder='Anzahl angeben (optional)'
							value={achievement.affirmationsDone}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>

						<Title level={5}>Häufigkeit Angabe der Stimmung</Title>
						<Input
							name='moodTracked'
							type='number'
							placeholder='Anzahl angeben (optional)'
							value={achievement.moodTracked}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>

						<Title level={5}>Anzahl nach bestimmten Stimmungen</Title>
						<Select
							mode='multiple'
							style={{ width: "100%", marginBottom: "10px" }}
							placeholder='Stimmung auswählen'
							value={selectedMoods}
							onChange={handleMoodSelect}
						>
							{moodOptions.map((mood) => (
								<Option key={mood.type} value={mood.name}>
									{mood.name}
								</Option>
							))}
						</Select>

						{selectedMoods.map((mood) => {
							const selectedMood = moodOptions.find(moody => moody.name === mood);
							return (
							<div key={mood} style={{ marginBottom: "10px" }}>
								<span>{mood}</span>
								<Input
									type='number'
									placeholder={`Anzahl angeben von ${mood}`}
									value={achievement.moodTypeTracked[selectedMood.type] || ""}
									onChange={(e) => handleMoodNumberChange(mood, e.target.value)}
									style={{ width: "200px", marginLeft: "50px" }}
								/>
							</div>
						)})}

						<Title level={5}>Login Streak</Title>
						<Input
							name='loginStreak'
							type='number'
							placeholder='Anzahl aufeinanderfolgende Logins (optional)'
							value={achievement.loginStreak}
							onChange={handleInput}
							style={{ marginBottom: "10px" }}
						/>
					</Col>

					<Row gutter={12} justify='end'>
						<Col>
							{/* if editing, show cancel button to clear inputs to be able to create new */}
							{isEditMode && (
								<Button
									type='secondary'
									onClick={() => {
										setAchievement({
											title: "",
											description: "",
											xpToEarn: "",
											isRepeatable: false,
											xpRequired: "",
											pointsRequired: "",
											videosSeen: "",
											tasksDone: "",
											quizzesDone: "",
											affirmationsDone: "",
											moodTracked: "",
											moodTypeTracked: "",
											loginStreak: "",
											categories: [],
										});
										setIsEditMode(false);
										setSelectedMoods([]);
									}}
									style={{ marginTop: "20px" }}
								>
									Bearbeiten abbrechen
								</Button>
							)}
						</Col>
						<Col>
							<Button
								type='primary'
								onClick={handleSave}
								disabled={!achievement.title && !file}
								style={{ marginTop: "20px" }}
							>
								{loading ? <ClipLoader /> : "Trophäe speichern"}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<AchievementList
							allAchievements={allAchievements}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default AchievementEditor;
